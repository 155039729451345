import React, { Component } from 'react'
import Flickity from 'react-flickity-component'
import styled from 'styled-components'
import Anime from 'react-anime';
import VisSensor from 'react-visibility-sensor';
import Slide from './slide';

const Screen = styled.div`
  max-width:100vw;
  overflow:hidden;
`

const OffscreenRight = styled.div`
  padding-left:1.33333rem;
  padding-right:1.33333rem;
  @media screen and (max-width: 47.99em) {
    padding-left:1.1111rem;
    padding-right:1.1111rem;
  }

  @media screen and (min-width:1249px) {
    margin-left: calc(((100vw) - 1249px) / 2);
  }

  * { outline:none }
`

const ArrowIcon = styled.div`
height:50px;
width:50px;

&.entered {
  transition:transform .2s;
  &:hover {
    transform:scale(1.1);
  }
}

@media (min-width:768px) {
  height:90px;
  width:90px;
}
`
const ArrowWrapper = styled.div`
  padding-bottom:61px;
  @media screen and (min-width: 768px) {
    padding-bottom:79px;
  }
`


export default class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      isDragging: false
    };

    this.sliderOptions = {
      pageDots: false,
      prevNextButtons: false,
      wrapAround: false,
      initialIndex: 0,
      cellAlign: 'left',
      disableImagesLoaded: true
    };

    this.arrow = React.createRef();
    this.flkty = null;
  }

  componentDidMount() {
    if (this.flkty) {
      this.flkty.on('pointerDown', ()=>{
        this.setState({
          // ...this.state,
          isDragging: true
        })
      })

      this.flkty.on('pointerUp', ()=>{
        setTimeout(()=>{
          this.setState({
            // ...this.state,
            isDragging: false
          })
        },50);
      })
    }
  }

  shouldComponentUpdate() {
    return !this.state.isDragging;
  }

  render() {
    return (
      <VisSensor
        active={!this.state.isVisible}
        onChange={(isVisible) => {
          this.setState(()=>{
            return {...this.state, isVisible: isVisible}
          })
        }}
      >
        <Screen className="relative">
          <OffscreenRight>
            {/* <Anime
              autoplay={this.state.isVisible}
              opacity={[(this.state.isDragging ? 0: 1),1]}
              duration={2400}
            > */}
              <Flickity
                flickityRef={c => this.flkty = c}
                className={'slider'}
                elementType={'div'}
                options={this.sliderOptions}
                disableImagesLoaded={true}
                static={true}
              >
                {this.props.items.map((item, cnt) =>
                  <Slide
                    item={item}
                    cnt={cnt}
                    isVisible={this.state.isVisible}
                    isDragging={this.state.isDragging}
                    key={`osci${item.heading}`}
                  />
                )}
              </Flickity>
            {/* </Anime> */}
          </OffscreenRight>

          <ArrowWrapper className="arrow absolute inset-0 pointer-events-none">
            <div className="o-wrapper flex flex-col h-full justify-center items-end">
              <Anime
                autoplay={this.state.isVisible}
                scale={[0, 1]}
                duration={!this.state.isDragging ? 600 : 0}
                delay={!this.state.isDragging ? 1000 : 0}
                complete={(s)=>{this.arrow && this.arrow.classList && this.arrow.classList.add('entered')}}
              >
                <ArrowIcon ref={(s)=> this.arrow = s} className="pointer-events-auto cursor-pointer" onClick={()=> this.flkty.next(true) }>
                  <svg viewBox="0 0 89.1 89.1" xmlns="http://www.w3.org/2000/svg"><circle cx="44.55" cy="44.55" r="44.55"/><path d="m40.788 35.838 9.5 8.712-9.5 8.712" fill="none" stroke="#fff" strokeWidth="4.752"/></svg>
                </ArrowIcon>
              </Anime>
            </div>
          </ArrowWrapper>
        </Screen>
      </VisSensor>
    )
  }
}