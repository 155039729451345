import React from "react"
import styled from "styled-components"
import { navigate } from "@reach/router"
import Anime from "react-anime"
import Image from "gatsby-image"

const StyledSlide = styled.div`
  margin-right: 0.5rem;
  width: calc(100% - 28px);
  /* transition: all 1s ease-in-out; */

  @media screen and (min-width: 500px) {
    width: 500px;
  }
  @media screen and (min-width: 640px) {
    height: 400px;
  }
  @media screen and (min-width: 768px) {
    height: 600px;
  }

  &:hover {
    h3 {
      text-decoration: underline;
    }
    .slide-bg {
      transform:none;
      opacity:1;
    }

  }

  .slide-bg {
    opacity: 0;
    transform: scaleX(1.1);
    transform-origin: left center;
    transition-duration: 0.4s;
  }
`



export default ({ item, cnt, isVisible, isDragging }) => {
  const [hovered, setHovered] = React.useState(false)

  return (
    <StyledSlide className="relative">
      {/* eslint-disable-next-line */}
      <a
        onClick={e => {  setTimeout(()=>{
          e.preventDefault();
          if(!isDragging) { navigate(`/guides#${item.link.post_name}`) }
        },60) }}
        href={`/guides#${item.link.post_name}`}
        className="flex flex-col items-stretch h-full overflow-hidden cursor-pointer relative z-10"
        onMouseEnter={e=>setHovered(true)}
        onMouseLeave={e=>setHovered(false)}
      >
        <div
          className="relative flex-auto flex flex-col img-container overflow-hidden"
          style={{ backgroundColor: "#fafafa" }}
        >
          <div className="slide-bg absolute inset-0 h-full w-full transition duration-500 ease-in-out img--cover">
            <img
              className=""
              src={require(`../images/bgs/${item.hover_background_colour}.jpg`)}
              alt=""
            />
          </div>
          <Anime
            scale={!hovered ? 1 : 1.1}
            easing="easeOutElastic"
            elasticity={.02}
            className="flex-auto flex flex-col items-center justify-center "
          >
            <Image
              fluid={item.image.localFile.childImageSharp.fluid}
              className="w-full flex-autos h-full"
              draggable={false}
              style={{maxHeight:'70%'}}
            />
          </Anime>
        </div>
        <h3 className="u-h4 pt-8">{item.heading}</h3>
      </a>

    </StyledSlide>
  )
}
