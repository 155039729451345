import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Image from "gatsby-image"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Slider from '../components/slider'

export default class Homepage extends React.Component {
  render() {
    const { acf, yoast_meta } = this.props.data.wordpressPage;
    const { hero, intro_boxes, prep_guides, what_we_fix, commitment, three_links, cta, logos } = acf;
    return (
      <Layout>
        <SEO yoast={yoast_meta} />

        <Hero
          heading={hero.hero.heading}
          content={hero.hero.content}
          image={hero.hero.image.localFile.childImageSharp.fluid}
          buttons={hero.hero.buttons}
          bg={hero.hero.background_colour || 'tan'}
        />


        {/* Logos */}
        <section className="logos my-12 lg:my-16">
          <div className="o-wrapper">
            <div className="grid md:grid-cols-5 grid-cols-4 md:gap-10 gap-6 items-center justify-center">
              {logos.map((l, i) =>
                <img key={'hp-logo-'+l+i} src={l} alt="Logo" className={`mx-auto transform scale-75 sm:scale-90 md:scale-100 ${i >= 4 ? 'hidden md:block': ''}`} />
              )}
            </div>
          </div>
        </section>

        {/* Two Col Boxes */}
        <section className="md:mb-20 sm:mb-16 mb-12">
          <div className="o-wrapper">
            <div className="grid sm:grid-cols-2 gap-6 row-gap-6">
              {intro_boxes.map((box, i) =>
                <div className="flex flex-col" key={`hp-box-${i}`}>
                  <div className={`p-8 md:py-16 md:px-12 bg-tertiary flex flex-col flex-auto justify-end border-b-7 border-${i === 0 ? 'green': 'tan'}`}>
                    <div className="flex flex-col flex-auto justify-center">
                      <h2 className="u-h4 sm:u-h3 mb-4">{ box.heading }</h2>
                      <p>{ box.content }</p>
                    </div>
                    <div className="mt-6">
                      <Link to={box.link.url} target={box.link.target} className="c-btn--underline">{ box.link.title }</Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Prep */}
        <section className="hp-prepare md:my-28 sm:my-20 my-16">
          <div className="o-wrapper">
            <div className="-ml-6 flex flex-wrap">
              <div className="pl-6 md:w-1/2 w-full">
                <Image fluid={prep_guides.image.localFile.childImageSharp.fluid} draggable={false} />
              </div>
              <div className="pl-6 md:w-1/2 flex flex-col justify-center items-center w-full">
                <div className="pt-8 pb-2 sm:pt-10 pb-0 md:py-0 md:p-6 md:max-w-md md:mx-auto w-full">
                  <h2 className="u-h2">{prep_guides.heading}</h2>
                  <p className="mt-6 mb-5 leading-relaxed">{prep_guides.content}</p>
                  <Link to={prep_guides.link.url} target={prep_guides.link.target} className="c-btn--underline">{ prep_guides.link.title }</Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* What we fix */}
        <section className="md:my-32 sm:my-20 my-16">
          <div className="o-wrapper">
            <div className="-ml-6 flex flex-wrap">
              <div className="pl-6 md:w-1/2">
                <h2 className="lg:u-h1 u-h2">{what_we_fix.heading}</h2>
              </div>
              <div className="pl-6 md:w-1/2 mt-6 md:mt-2">
                <p>{what_we_fix.content}</p>
                <Link to={what_we_fix.link.url} target={what_we_fix.link.target} className="c-btn--underline mt-5">{ what_we_fix.link.title }</Link>
              </div>
            </div>
          </div>


          <div className="xl:mt-20 md:mt-16 mt-10">
            <Slider items={what_we_fix.guides} />
          </div>

        </section>

        {/* Commitment */}
        <section className="md:my-32 sm:my-20 my-16 our-commitment max-w-screen">
          <div className="o-wrapper">
            <div className="flex flex-wrap md:flex-no-wrap">
              <div className="our-commitment__left relative z-10 bg-green md:mt-20">
                <div className="bg-white lg:p-12 lg:py-16 md:p-8 sm:pb-10 pb-8 md:mb-10 relative our-commitment__text">
                  <h2 className="u-h2 mb-5">{commitment.heading}</h2>
                  <p>{commitment.content}</p>
                </div>
              </div>
              <div className="our-commitment__right breakout-wrapper-until-md img--cover">
                <Image className="h-full " fluid={commitment.image.localFile.childImageSharp.fluid} draggable={false} />
              </div>
            </div>
          </div>
        </section>

        {/* Three Cols */}
        <section className="md:my-32 sm:my-20 my-16 three-cols">
          <div className="o-wrapper">
            <div className="grid md:grid-cols-3 sm:grid-cols-2 md:gap-16 gap-12">
              {three_links.map(c =>
                <div className="flex flex-col items-center text-center sm:text-left sm:items-start" key={`hp-link-`+c.heading}>
                  <div className="flex-auto">
                    <h4 className="-ml-4 sm:ml-0 u-h4 mb-4 flex items-center justify-center sm:justify-start">
                      <img src={c.icon.localFile.publicURL} className="mt-1 mr-3" alt={c.heading} />
                      <span className="block">{c.heading}</span>
                    </h4>
                    <p>{c.content}</p>
                  </div>
                  <Link to={c.link.url} target={c.link.target} className="c-btn bg-black text-white inline-block mt-6 hover:text-black hover:bg-blue-light">{ c.link.title }</Link>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* See Status */}
        <section className="my-16 md:my-20 lg:my-24 xl:my-28">
          <div className="o-wrapper">
            <div className="flex flex-wrap">
              <div className="md:w-1/2 w-full flex flex-col justify-end">
                <div className="bg-tertiary flex-auto img--cover flex">
                  <Image fluid={cta.left_column.image.localFile.childImageSharp.fluid} className="flex-auto " draggable={false} />
                </div>
                <div className="bg-white pt-6 pb-10 md:pb-0 md:px-4">
                  <h4 className="u-h4">{cta.left_column.heading}</h4>
                  <p className="mb-5 md:mt-4 mt-2">{cta.left_column.content}</p>
                  <a href={cta.left_column.button.url} target={cta.left_column.button.target} className="inline-block c-btn bg-black text-white hover:text-black hover:bg-blue-light">{cta.left_column.button.title}</a>
                </div>
              </div>
              <div className="md:w-1/2 w-full bg-blue-light flex flex-col items-center justify-center text-center md:py-32 sm:py-20 py-12 px-6">
                <div className="max-w-xs">
                  <h3 className="u-h2" dangerouslySetInnerHTML={{ __html: cta.right_column.heading }} />
                  <p className="mb-5 mt-4" dangerouslySetInnerHTML={{ __html: cta.right_column.content }} />
                  <a href={cta.right_column.button.url} target={cta.right_column.button.target} className="c-btn inline-block bg-black text-white hover:text-black hover:bg-white">{cta.right_column.button.title}</a>
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    )
  }
}


export const query = graphql`
  query Home {
    wordpressPage(slug: {eq: "home" }) {
      title
      ...Yoast
      acf {
        hero {
          ...Hero
        }
        intro_boxes {
          heading
          content
          link {
            title
            url
            target
          }
        }
        prep_guides {
          heading
          content
          link {
            title
            url
            target
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth:586) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        what_we_fix {
          heading
          content
          link {
            title
            url
            target
          }
          guides {
            heading
            hover_background_colour
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth:800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            link {
              post_name
            }
          }
        }
        commitment {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth:650) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        three_links {
          heading
          content
          icon {
            localFile {
              publicURL
            }
          }
          link {
            title
            url
            target
          }
        }
        cta {
          left_column {
            heading
            content
            button {
              title
              url
              target
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          right_column {
            heading
            content
            button {
              title
              url
              target
            }
          }
        }
        logos
      }
    }
  }
`
